.cashback-banner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #0061cc;
  z-index: 100; }
  @media only screen and (max-width: 1019px) {
    .cashback-banner {
      min-width: 375px; } }
  @media only screen and (min-width: 1020px) {
    .cashback-banner {
      min-width: 985px; } }
  .cashback-banner + .landing {
    position: relative; }
    @media only screen and (max-width: 1019px) {
      .cashback-banner + .landing {
        margin-top: 57px; }
        .cashback-banner + .landing .hnav-mob .hnav__list {
          top: 57px; } }
    @media only screen and (min-width: 1020px) {
      .cashback-banner + .landing {
        margin-top: 40px; } }
  .cashback-banner .holder {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center; }
    @media only screen and (max-width: 1019px) {
      .cashback-banner .holder {
        height: 57px; } }
    @media only screen and (min-width: 1020px) {
      .cashback-banner .holder {
        height: 40px; } }
  @media only screen and (max-width: 1019px) {
    .cashback-banner .cashback-icon {
      display: none; } }
  @media only screen and (min-width: 1020px) {
    .cashback-banner .cashback-icon {
      display: block;
      width: 22px;
      height: 22px; } }
  .cashback-banner .cashback-msg {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    line-height: 1.19;
    color: #fff; }
    @media only screen and (min-width: 1020px) {
      .cashback-banner .cashback-msg {
        margin-left: 14px; } }
  .cashback-banner .cashback-highlight {
    font-weight: 900;
    color: #00ff30; }
    @media only screen and (max-width: 1019px) {
      .cashback-banner .cashback-highlight {
        display: block; } }

/*
  @include respond-to(handhelds) {
  }
  @include respond-to(wide-screens) {
  }
*/
