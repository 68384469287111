.promoBanner {
  background: url("./banner-bg.svg") #181B2E;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100; }
  @media only screen and (max-width: 1019px) {
    .promoBanner {
      min-width: 375px; } }
  @media only screen and (min-width: 1020px) {
    .promoBanner {
      min-width: 985px; } }
  .promoBanner + .landing {
    position: relative;
    margin-top: 56px; }
    .promoBanner + .landing .hnav-mob .hnav__list {
      top: 56px; }

.promoBanner_holder {
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "Aleo", serif;
  font-size: 20px;
  color: #fff; }

.promoBanner_highlight {
  color: #2CC361;
  font-weight: 700; }

/*
@include respond-to(handhelds) {
}
@include respond-to(wide-screens) {
}
*/
