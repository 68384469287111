.slider-combined .slick-title {
  text-align: center; }
  @media only screen and (max-width: 1019px) {
    .slider-combined .slick-title {
      line-height: 1.4;
      padding-left: 26px;
      padding-right: 26px; } }

.slider-combined .slick-slider {
  display: flex;
  flex-direction: column-reverse; }

.slider-combined .how-slider__list .how-slider__list-item {
  display: flex;
  justify-content: space-between; }
  @media only screen and (max-width: 1019px) {
    .slider-combined .how-slider__list .how-slider__list-item {
      align-items: center;
      margin-top: 35px; } }
  @media only screen and (min-width: 1020px) {
    .slider-combined .how-slider__list .how-slider__list-item {
      align-items: flex-start;
      width: 300px; } }

.slider-combined .how-slider__list img {
  display: block; }
  @media only screen and (max-width: 1019px) {
    .slider-combined .how-slider__list img {
      width: 80px;
      height: 80px; } }
  @media only screen and (min-width: 1020px) {
    .slider-combined .how-slider__list img {
      width: 65px;
      height: 65px;
      margin-top: 5px; } }

.slider-combined .how-slider__list .how-slider__list-item-text {
  font-family: "Roboto", sans-serif;
  color: #4e526b;
  font-size: 16px;
  line-height: 1.5; }
  @media only screen and (max-width: 1019px) {
    .slider-combined .how-slider__list .how-slider__list-item-text {
      text-align: left;
      width: 233px; } }
  @media only screen and (min-width: 1020px) {
    .slider-combined .how-slider__list .how-slider__list-item-text {
      width: 220px; } }

.slider-combined .how-slider__name {
  font-family: "Roboto", sans-serif;
  line-height: 1.5;
  color: #4e526b;
  font-weight: bold; }
  @media only screen and (max-width: 1019px) {
    .slider-combined .how-slider__name {
      font-size: 16px; } }
  @media only screen and (min-width: 1020px) {
    .slider-combined .how-slider__name {
      font-size: 14px; } }

.slider-combined .how-slider__msg {
  font-family: "Aleo", serif;
  font-style: italic;
  letter-spacing: 0.25px;
  color: #6d7393; }
  @media only screen and (max-width: 1019px) {
    .slider-combined .how-slider__msg {
      font-size: 20px;
      line-height: 1.3;
      letter-spacing: 0.25px; } }
  @media only screen and (min-width: 1020px) {
    .slider-combined .how-slider__msg {
      margin-top: 2px;
      font-size: 18px;
      line-height: 1.44;
      min-height: 75px; } }

@media only screen and (min-width: 1020px) {
  .slider-combined .how-slider__list-item-holder {
    display: flex !important;
    justify-content: space-between;
    align-items: flex-start; } }

.slider-combined .how-slider__list-item-holder .slick-item {
  display: flex !important;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 300px;
  border-radius: 15px;
  padding-top: 18px; }
  @media only screen and (max-width: 1019px) {
    .slider-combined .how-slider__list-item-holder .slick-item {
      width: 250px !important;
      margin-left: 20px; } }
  @media only screen and (min-width: 1020px) {
    .slider-combined .how-slider__list-item-holder .slick-item {
      width: 290px !important; } }
  .slider-combined .how-slider__list-item-holder .slick-item.slick-item_academia {
    background-color: #feeda1; }
  .slider-combined .how-slider__list-item-holder .slick-item.slick-item_students {
    background-color: #c2f2d1; }
  .slider-combined .how-slider__list-item-holder .slick-item.slick-item_businesses {
    background-color: #cdd8f9; }

.slider-combined .how-slider__list-item-holder .item-tag {
  height: 41px;
  border-radius: 20.5px;
  background-color: #fff;
  padding: 0 24px;
  display: inline-flex;
  align-items: center;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  line-height: 1.5;
  color: #4e526b; }

.slider-combined .how-slider__list-item-holder .item-title {
  font-family: "Aleo", serif;
  font-size: 18px;
  line-height: 1.5;
  color: #31354f;
  margin-top: 16px;
  text-align: center;
  letter-spacing: 0.24px;
  padding: 0 20px; }

.slider-combined .how-slider__list-item-holder .item-img {
  margin-top: auto;
  display: block; }

.slider-combined .how-slider__list-item-holder .academia-img1 {
  width: 194px;
  height: 160px;
  margin-bottom: -12px; }

.slider-combined .how-slider__list-item-holder .academia-img2 {
  width: 143px;
  height: 152px;
  margin-bottom: -8px; }

.slider-combined .how-slider__list-item-holder .academia-img3 {
  width: 174px;
  height: 177px;
  margin-bottom: -26px; }

.slider-combined .how-slider__list-item-holder .students-img1 {
  width: 135px;
  height: 142px; }

.slider-combined .how-slider__list-item-holder .students-img2 {
  width: 138px;
  height: 134px;
  margin-left: -10px; }

.slider-combined .how-slider__list-item-holder .students-img3 {
  width: 155px;
  height: 137px;
  margin-right: -10px; }

.slider-combined .how-slider__list-item-holder .business-img1 {
  width: 182px;
  height: 164px;
  margin-bottom: -30px; }

.slider-combined .how-slider__list-item-holder .business-img2 {
  width: 140px;
  height: 170px;
  margin-bottom: -26px; }

.slider-combined .how-slider__list-item-holder .business-img3 {
  width: 181px;
  height: 115px;
  margin-bottom: 15px; }

@media only screen and (max-width: 1019px) {
  .slider-combined .how-slider__list-item-holder li button {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin: 0 9px;
    background-color: #dfdfdf;
    transition: background .3s ease;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden; } }

.slider-combined .how-slider__list-item-holder li.slick-active button {
  background-color: #1c2036; }

@media only screen and (min-width: 1020px) {
  .slider-combined .how-slider__list-item-holder .slick-static {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; } }

.slider-combined_1 .how-slider__switch-btn, .slider-combined_business .how-slider__switch-btn, .slider-combined_academia .how-slider__switch-btn {
  position: relative;
  display: flex !important; }
  @media only screen and (max-width: 1019px) {
    .slider-combined_1 .how-slider__switch-btn, .slider-combined_business .how-slider__switch-btn, .slider-combined_academia .how-slider__switch-btn {
      padding-left: 24px;
      padding-right: 24px;
      margin-top: 40px;
      margin-bottom: 30px; } }
  @media only screen and (min-width: 1020px) {
    .slider-combined_1 .how-slider__switch-btn, .slider-combined_business .how-slider__switch-btn, .slider-combined_academia .how-slider__switch-btn {
      padding: 0 22px;
      justify-content: center;
      margin-top: 60px;
      margin-bottom: 32px; } }
  .slider-combined_1 .how-slider__switch-btn:after, .slider-combined_business .how-slider__switch-btn:after, .slider-combined_academia .how-slider__switch-btn:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    border-radius: 1.5px;
    background-color: #e4e5eb;
    right: 0; }
  .slider-combined_1 .how-slider__switch-btn li, .slider-combined_business .how-slider__switch-btn li, .slider-combined_academia .how-slider__switch-btn li {
    flex: 3 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-family: "Roboto", sans-serif;
    opacity: 0.5;
    font-size: 18px;
    line-height: 1.5;
    color: #4e526b;
    padding-bottom: 12px; }
    @media only screen and (min-width: 1020px) {
      .slider-combined_1 .how-slider__switch-btn li, .slider-combined_business .how-slider__switch-btn li, .slider-combined_academia .how-slider__switch-btn li {
        margin: 0 50px;
        flex: none; } }
    .slider-combined_1 .how-slider__switch-btn li:not(.wcs__switch-btn-item_active), .slider-combined_business .how-slider__switch-btn li:not(.wcs__switch-btn-item_active), .slider-combined_academia .how-slider__switch-btn li:not(.wcs__switch-btn-item_active) {
      cursor: pointer; }
    .slider-combined_1 .how-slider__switch-btn li.slick-active, .slider-combined_business .how-slider__switch-btn li.slick-active, .slider-combined_academia .how-slider__switch-btn li.slick-active {
      color: #23a652;
      font-weight: bold;
      opacity: 1;
      position: relative; }
      .slider-combined_1 .how-slider__switch-btn li.slick-active:after, .slider-combined_business .how-slider__switch-btn li.slick-active:after, .slider-combined_academia .how-slider__switch-btn li.slick-active:after {
        content: "";
        position: absolute;
        bottom: 0;
        width: 80px;
        height: 2px;
        border-radius: 1.5px;
        background-color: #23a652;
        z-index: 1;
        left: 0;
        right: 0;
        margin: 0 auto; }

.slider-combined_business .how-slider__switch-btn li {
  color: #1c2036;
  opacity: 1; }
  .slider-combined_business .how-slider__switch-btn li.slick-active {
    color: #225ee8; }
    .slider-combined_business .how-slider__switch-btn li.slick-active:after {
      background-color: #225ee8; }

.slider-combined_academia .how-slider__switch-btn li {
  color: #1c2036;
  opacity: 1; }
  .slider-combined_academia .how-slider__switch-btn li.slick-active {
    color: #036e6f; }
    .slider-combined_academia .how-slider__switch-btn li.slick-active:after {
      background-color: #036e6f; }

.slider-combined_clp .slick-title {
  font-family: Axiforma, sans-serif;
  font-size: 26px;
  font-weight: bold;
  line-height: 1.4;
  letter-spacing: 0.36px;
  color: #023135; }

.slider-combined_clp .how-slider__switch-btn {
  display: inline-flex !important;
  border-radius: 6px;
  background-color: #d0e6f4; }
  @media only screen and (max-width: 1019px) {
    .slider-combined_clp .how-slider__switch-btn {
      margin: 40px 20px 32px; } }
  @media only screen and (min-width: 1020px) {
    .slider-combined_clp .how-slider__switch-btn {
      margin: 40px auto 32px; } }
  .slider-combined_clp .how-slider__switch-btn li {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 126px;
    height: 32px;
    font-family: 'Axiforma',sans-serif;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.19px;
    color: #016fbb;
    cursor: pointer; }
  .slider-combined_clp .how-slider__switch-btn .slick-active {
    border-radius: 6px;
    background-color: #016fbb;
    color: #fff; }

/*
@include respond-to(handhelds) {
}
@include respond-to(wide-screens) {
}
*/
