.invest {
  background-color: #f7f8fa;
  /*
  .invest1-icon {
    width: 23px;
    height: 27px;
  }
  .invest2-icon {
    width: 22px;
    height: 26px;
  }
  .invest3-icon {
    width: 23px;
    height: 27px;
  }
  */ }
  .invest .title {
    font-family: "Aleo", serif;
    font-size: 26px;
    line-height: 1.4;
    letter-spacing: 0.36px;
    text-align: center;
    color: #1c2036; }
  @media only screen and (max-width: 1019px) {
    .invest .invest-list {
      margin-top: 40px; } }
  @media only screen and (min-width: 1020px) {
    .invest .invest-list {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-top: 45px; } }
  .invest .invest-item {
    background-color: #fff;
    padding: 24px 20px 20px;
    border-radius: 12px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
    border: solid 1px #fff; }
    @media only screen and (max-width: 1019px) {
      .invest .invest-item:not(:first-child) {
        margin-top: 24px; } }
    @media only screen and (min-width: 1020px) {
      .invest .invest-item {
        width: 305px; }
        .invest .invest-item:hover .link-wrapper {
          max-height: 50px; } }
  .invest .invest-item-title {
    display: flex;
    justify-content: flex-start;
    align-items: center; }
  .invest .svg-icon {
    display: block; }
  .invest .invest-item-title-text {
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: 500;
    color: #1c2036;
    margin-left: 18px; }
  .invest .invest-item-ul {
    margin-top: 15px; }
  .invest .invest-item-li {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    line-height: 1.75;
    color: #1c2036; }
  .invest .link {
    display: inline-block;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.22px;
    color: #24a652;
    padding-top: 10px; }
  @media only screen and (min-width: 1020px) {
    .invest .link-wrapper {
      transition: max-height .3s ease;
      max-height: 0;
      overflow: hidden; } }

/*
  @include respond-to(handhelds) {
  }
  @include respond-to(wide-screens) {
  }
*/
