.menuDropdown {
  font-family: "Roboto", sans-serif; }
  .menuDropdown .title {
    color: #24A652;
    position: relative; }
    @media only screen and (max-width: 1019px) {
      .menuDropdown .title {
        width: 100%;
        text-align: left;
        padding: 6px 16px;
        font-size: 20px;
        line-height: normal; }
        .menuDropdown .title:after {
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          display: block;
          z-index: 2;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid #24A652;
          border-bottom: none;
          top: 15px;
          right: 5px; }
        .menuDropdown .title.open:after {
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          display: block;
          z-index: 2;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-bottom: 5px solid #24A652;
          border-top: none; } }
    @media only screen and (min-width: 1020px) {
      .menuDropdown .title {
        font-size: 14px;
        letter-spacing: 0.1px;
        line-height: 21px; }
        .menuDropdown .title:after {
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          display: block;
          z-index: 2;
          border-left: 4.5px solid transparent;
          border-right: 4.5px solid transparent;
          border-top: 4px solid #24A652;
          border-bottom: none;
          top: 8px;
          right: -18px; }
        .menuDropdown .title.open:after {
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          display: block;
          z-index: 2;
          border-left: 4.5px solid transparent;
          border-right: 4.5px solid transparent;
          border-bottom: 4px solid #24A652;
          border-top: none; } }
  .menuDropdown .menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
    @media only screen and (max-width: 1019px) {
      .menuDropdown .menu {
        padding: 8px 0 8px 24px;
        gap: 16px; } }
    @media only screen and (min-width: 1020px) {
      .menuDropdown .menu {
        gap: 12px;
        position: absolute;
        margin-top: 8px;
        right: 0;
        padding: 20px;
        border-radius: 10px;
        border: 1px solid #CCC;
        background: #FFF;
        box-shadow: 0 0 20px 0 rgba(109, 115, 147, 0.12); } }
  .menuDropdown .item {
    color: #31354F;
    line-height: 21px;
    /* 150% */
    letter-spacing: 0.1px; }
    @media only screen and (max-width: 1019px) {
      .menuDropdown .item {
        font-size: 16px; } }
    @media only screen and (min-width: 1020px) {
      .menuDropdown .item {
        font-size: 14px; } }
    .menuDropdown .item:hover {
      color: #24A652; }

.dropdown {
  position: relative; }
  .dropdown:hover .dropdown-list {
    display: block; }

.dropdown-title {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  letter-spacing: 0.1px;
  color: #fff;
  position: relative;
  padding-bottom: 12px;
  cursor: pointer; }
  .dropdown-title:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    display: block;
    z-index: 2;
    border-left: 3.5px solid transparent;
    border-right: 3.5px solid transparent;
    border-top: 4px solid #fff;
    border-bottom: none;
    top: 6px;
    right: -15px; }

.dropdown-list {
  background: #fff;
  min-width: 132px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 8px;
  border: solid 1px #e4e5eb;
  position: absolute;
  top: 100%;
  right: -15px;
  display: none; }

.dropdown-list-item {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 21px;
  color: #4e526b; }

.dropdown-list-item__active {
  background: #24a652;
  color: #fff; }

.dropdown-list-item-link {
  display: block;
  padding: 4px 16px;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit; }

/*
  @include respond-to(handhelds) {
  }
  @include respond-to(wide-screens) {
  }
*/
